export const ClientMode = {
  CAPTURE: "capture",
  FULL: "full",
  EXCHANGE: "exchange",
  NETWORK_REPORTS_ONLY: "networkReportsOnly",
};

export const SAVED_FILTERS = "exchange_saved_filters";
export const TRIAGE_METADATA_VIEW = "exchange_doc_triage_metadata";
export const TRANSFER_DOCS_OPTION = "exchange_transfer_documents";
